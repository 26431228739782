import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()

const getFingerprint = async () => {
  const fp = await fpPromise
  const result = await fp.get()
  return result.visitorId
}

const fpInit = async () => {
  const visitorId = await getFingerprint()
  const domain = location.hostname.split('.').slice(-2).join('.')
  document.cookie = `FP-TOKEN=${visitorId}; domain=${domain}; path=/`
}

fpInit()