document.addEventListener('DOMContentLoaded', () => {
    let showPasswordElements = Array.from(document.querySelectorAll('.showpwd-wrap'))
    if (showPasswordElements.length === 0) return null
    showPasswordElements.map(showPasswordElement => {
        let input = showPasswordElement.querySelector('input[type="password"]')
        let button = showPasswordElement.querySelector('.showpwd')
        if (!input) return null
        if (!button) return null

        button.addEventListener('click', () => {
            if (input.type === 'password') {
                input.type = 'text'
                button.setAttribute('aria-pressed', 'true')
                button.classList.replace('fa-eye', 'fa-eye-slash')
            } else {
                input.type = 'password'
                button.setAttribute('aria-pressed', 'false')
                button.classList.replace('fa-eye-slash', 'fa-eye')
            }
        })
    })
})