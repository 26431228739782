// const images = require.context('../images', true)
// require('@rails/ujs').start()
// require('@rails/activestorage').start()

import 'bootstrap'
import 'inputmask/dist/inputmask.js'

// import(/* webpackPreload: true */ '../stylesheets/web')
import '../stylesheets/web.scss'

import '../src/opt_check.js'
import '../src/fingerprint.js'
import '../src/showpassword.js'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import '@fortawesome/fontawesome-free/css/all.css'
import { i18next } from '../src/i18next_init'

var dsn = document
  .querySelector('meta[name="sentry-dsn"]')
  ?.getAttribute('content')
if (typeof dsn !== 'undefined') {
  Sentry.init({
    dsn: dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

window.onload = function () {
  if (typeof ga !== 'undefined')
    ga(function (tracker) {
      var clientId = ga.getAll()[0].get('clientId')
      document.querySelector('.ga_cid').value = clientId
    })

  Inputmask().mask(document.querySelectorAll('.masked'))
}
